function populateDropdownOptions(id, options, selectedValue) {
  $("." + id)
    .empty()
    .html("<option value=''>Please select</option>");
  if (options !== null && options.length > 0) {
    options.forEach(function (option) {
      const optionValue = option.id;
      const optionText = option.name ? option.name : " ";
      const selected = selectedValue == optionValue ? "selected" : "";

      $("." + id).append(
        '<option value="' +
          optionValue +
          '" ' +
          selected +
          ">" +
          optionText +
          "</option>"
      );
    });
  }
}

function updatePayoutAgents(payout_agents, selectedPayingAgents) {
  const payoutAgentContainer = $("#payout-agent-container");

  payoutAgentContainer.html("");

  if (payout_agents) {
    payout_agents.forEach(function (agent) {
      const agentRow = $("<tr>").attr("id", "payout-agent-" + agent.id);
      const checkboxCell = $("<td>").addClass("table_bordered");
      const codeCell = $("<td>").addClass("table_bordered").text(agent.code);
      const nameCell = $("<td>").addClass("table_bordered").text(agent.name);

      const isChecked = selectedPayingAgents.includes(agent.id);

      const payoutAgentCheckbox = $("<input>").attr({
        type: "checkbox",
        name: "payout_agent_allocations[paying_agent_ids][]",
        value: agent.id,
        checked: isChecked
      });

      checkboxCell.append(payoutAgentCheckbox);

      agentRow.append(checkboxCell, codeCell, nameCell);

      payoutAgentContainer.append(agentRow);
    });
  }
}

var filterData = {};
function fetchData() {
  return new Promise(function (resolve, reject) {
    $.ajax({
      type: "GET",
      url: window.location.pathname,
      dataType: "json",
      data: filterData,
      error: function (xhr, status, error) {
        const e = "AJAX Error: " + status + error;
        console.error(e);
        reject(e);
      },
      success: function (response) {
        if (!response) {
          resolve();
          return;
        }
        const sendingAgents = response.sending_agents;
        populateDropdownOptions(
          "send-agent-allocation",
          sendingAgents || [],
          filterData.sending_agent
        );
        const destinationCountries = response.destination_countries;
        populateDropdownOptions(
          "destination-country-allocation",
          destinationCountries || [],
          filterData.destination_country
        );
        const payingAgents = response.paying_agents;
        const selectedPayingAgents = response.selected_paying_agent_ids
        updatePayoutAgents(payingAgents, selectedPayingAgents);
       
      },
    });
  });
}

$(document).on("change", ".source-country-allocation", function () {
  $(".send-agent-allocation")
    .empty()
    .html("<option value=''>Please select</option>");
  const sourceCountry = $(this).val();
  filterData.source_country = sourceCountry;
  $("#source-country-hidden").val(sourceCountry);
  delete filterData.sending_agent;
  delete filterData.destination_country;
  delete filterData.paying_agents;
  fetchData().then(function () {});
});

$(document).on("change", ".send-agent-allocation", function () {
  const sendingAgent = $(this).val();
  $("#sending-agent-hidden").val(sendingAgent);
  filterData.sending_agent = sendingAgent;
  delete filterData.destination_country;
  delete filterData.paying_agents;
  fetchData().then(function () {});
});

$(document).on("change", ".destination-country-allocation", function () {
  const destinationCountry = $(this).val();
  $("#destination-country-hidden").val(destinationCountry);
  filterData.destination_country = destinationCountry;
  delete filterData.paying_agents;
  fetchData().then(function () {});
});
